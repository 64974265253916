<template>
  <section class="container-description">
    <mkp-card>
      <template v-slot:header>
        <h1 class="product-title">{{ $t("product.description.title") }}</h1>
      </template>

      <template v-slot:body>
        <mkp-text-field
          class="description-product"
          v-html="description"
        ></mkp-text-field>
      </template>

      <template v-slot:footer>
        <mkp-text-field
          v-for="(tag, index) in hastags"
          :key="index"
          class="hashtag-product"
        >
          {{ tag.label }}
        </mkp-text-field>
      </template>
    </mkp-card>
  </section>
</template>

<script>
import MkpTextField from "../../simple/text-field/mkp-text-field";
import MkpCard from "../../simple/card/mkp-card";
import "./product-scss/mkp-description-product.scss";
export default {
  name: "MkpDescriptionProduct",
  components: { MkpCard, MkpTextField },
  props: {
    description: {
      type: String,
      default: null
    },
    hastags: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>

<style scoped></style>

<template>
  <Section class="detail-product">
    <mkp-carousel
      v-if="medias"
      :responsive="carouselOptions"
      class="mkp-carousel"
    >
      <mkp-carrousel-slide v-for="(media, index) in medias" :key="index">
        <mkp-image :lazy-src="require('@/assets/images/' + media.url)" />
      </mkp-carrousel-slide>
      <template v-slot:prev-button>
        <div class="img-btn-container">
          <mkp-image class="btn-prev" :lazy-src="navPrev" />
        </div>
      </template>
      <template v-slot:next-button>
        <div class="img-btn-container">
          <mkp-image class="btn-next" :lazy-src="navNext" />
        </div>
      </template>
    </mkp-carousel>

    <mkp-card class="card-infos">
      <!--      <template v-slot:header>-->
      <!--        <h1 class="title-info">{{ $t("product.detail.title") }}</h1>-->
      <!--      </template>-->

      <template v-slot:body>
        <mkp-text-field>{{ $t("product.detail.delivered") }}</mkp-text-field>
      </template>

      <template v-slot:footer>
        <div v-for="(icon, index) in delivered" :key="index" class="icone-info">
          <mkp-image :lazy-src="icon.url" />
          <mkp-text-field>{{ icon.text }}</mkp-text-field>
        </div>
      </template>
    </mkp-card>
  </Section>
</template>

<script>
import MkpCarousel from "../../complex/caroussel/mkp-carousel";
import MkpCarrouselSlide from "../../complex/caroussel/mkp-carousel-slide";
import MkpImage from "../../simple/image/mkp-image";
import MkpCard from "../../simple/card/mkp-card";
import MkpTextField from "../../simple/text-field/mkp-text-field";
import "./product-scss/mkp-detail-product.scss";

export default {
  name: "MkpDetailProduct",
  components: {
    MkpTextField,
    MkpCard,
    MkpImage,
    MkpCarrouselSlide,
    MkpCarousel
  },
  props: {
    medias: {
      type: Array,
      default: () => {
        return [];
      }
    },
    options: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: () => {
    return {
      icons: {
        Documentation: {
          url: require("./../../../assets/images/documentation.png"),
          text: "Documentation"
        },
        Contrat: {
          url: require("./../../../assets/images/contract.png"),
          text: "Contrat"
        },
        Support: {
          url: require("./../../../assets/images/support.png"),
          text: "Support"
        },
        Dashboard: {
          url: require("./../../../assets/images/dashboard.png"),
          text: "Dashboard"
        },
        Sandbox: {
          url: require("./../../../assets/images/sandbox.png"),
          text: "Sandbox"
        }
      },
      navPrev: require("@/assets/images/left.png"),
      navNext: require("@/assets/images/right.png"),
      carouselOptions: {
        1441: {
          items: 1,
          center: false
        },
        1280: {
          items: 1,
          center: false
        },
        1024: {
          items: 1,
          center: false
        },
        200: {
          items: 1,
          gutter: 20,
          center: true
        }
      }
    };
  },
  computed: {
    delivered() {
      let delivery = [];
      for (let i = 0; i < this.options.length; i++) {
        if (this.icons[this.options[i].label]) {
          delivery.push(this.icons[this.options[i].label]);
        }
      }
      return delivery;
    }
  }
};
</script>

<style scoped></style>

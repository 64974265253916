<template>
  <mkp-button class="btn-return-products" @click="$emit('click')">
    <mkp-image :lazy-src="returnArrow" />
    {{ $t("back") }}
  </mkp-button>
</template>

<script>
import "./mkp-button-back.scss";
import MkpButton from "@/components/simple/button/mkp-button.vue";
import MkpImage from "@/components/simple/image/mkp-image.vue";
export default {
  name: "MkpBackButton",
  components: {
    MkpButton,
    MkpImage
  },
  data: () => {
    return {
      returnArrow: require("@/assets/images/arrow-left-grey.svg")
    };
  }
};
</script>

<template>
  <section class="mkp-breadcrumbs">
    <mkp-button-back @click="goCatalogue" />
    <mkp-text-field>{{ title }}</mkp-text-field>
  </section>
</template>

<script>
import MkpButtonBack from "@/components/simple/button-back/mkp-button-back.vue";
import MkpTextField from "../../simple/text-field/mkp-text-field";

import "./product-scss/mkp-breadcrumbs.scss";

export default {
  name: "MkpBreadcrumbs",
  components: { MkpTextField, MkpButtonBack },
  props: {
    title: {
      type: String,
      default: null
    }
  },
  methods: {
    goCatalogue() {
      this.$router.push({ name: "Catalogue" });
    }
  }
};
</script>

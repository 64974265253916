<template>
  <div>
    <div class="mkp-breadcrumbs">
      <mkp-button-back @click="$router.back()" />
      <h1 class="product-title">{{ product.label }}</h1>
    </div>
    <div id="redocContainer"></div>
  </div>
</template>

<script>
import * as redoc from "redoc/bundles/redoc.standalone";
import MkpButtonBack from "@/components/simple/button-back/mkp-button-back.vue";

export default {
  name: "MkpRedocWrapper",
  components: {
    MkpButtonBack
  },
  data() {
    return {
      product: this.$store.getters.getProduct,
      options_: {
        scrollYOffset: 50,
        hideDownloadButton: true
      }
    };
  },
  mounted() {
    if (this.specOrSpecUrl != null) {
      redoc.init(
        this.specOrSpecUrl(),
        this.options,
        document.getElementById("redocContainer")
      );
    }
  },
  methods: {
    specOrSpecUrl() {
      return require("@/assets/swagger/" + this.product.swagger);
    }
  }
};
</script>

<style scoped lang="scss">
.mkp-breadcrumbs {
  position: relative;
  padding: 0 0;
  margin: 0.5em 0.5em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .product-title {
    font-size: $font-xl;
    font-weight: 700;
    font-family: GOTHAM-BLACK;
    letter-spacing: 1.2px;
    color: $color-typo;
    margin-top: 0.5rem;
    padding: 0 36px;
  }

  @media only screen and (min-width: 800px) {
    display: block;
    .product-title {
      position: absolute;
      top: 0;
      left: 260px;
    }
  }
}
</style>

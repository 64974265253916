<template>
  <section class="container-mkp-product">
    <mkp-card>
      <template v-slot:header>
        <mkp-image :lazy-src="image" />
      </template>
      <template v-slot:body>
        <h3 class="product-title">{{ label }}</h3>
        <p v-if="version" class="maj-product">
          {{ $t("product.presentation.last_version") }}
          {{ date(version) }}
        </p>
        <mkp-text-field class="presentation-product">{{
          description
        }}</mkp-text-field>
      </template>
      <template v-slot:footer>
        <!--        <mkp-button class="btn-products" @click="addProduct()">-->
        <mkp-button
          class="btn-products"
          @click="$router.push('/products/' + $props.id + '/doc')"
        >
          <span>{{ $t("product.presentation.button") }}</span>
        </mkp-button>
      </template>
    </mkp-card>
  </section>
</template>

<script>
import MkpImage from "../../simple/image/mkp-image";
import MkpTextField from "../../simple/text-field/mkp-text-field";
import MkpCard from "../../simple/card/mkp-card";
import MkpButton from "../../simple/button/mkp-button";
import "./product-scss/mkp-presentation-product.scss";

export default {
  name: "MkpPresentationProduct",
  components: { MkpButton, MkpCard, MkpTextField, MkpImage },
  props: {
    id: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    version: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  },
  methods: {
    addProduct() {
      this.$emit("addToCart");
    },
    date(versionDate) {
      let listMounth = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ];
      let date = new Date(versionDate);
      let day = date.getDate();
      let mounth = date.getMonth();
      let year = date.getFullYear();

      return day + " " + listMounth[mounth] + " " + year;
    }
  }
};
</script>

<style scoped></style>

<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>
export default {
  name: "Product"
  /*mounted() {
    this.$store.dispatch(
      "loadProduct",
      process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_BASE_URL_API +
        "/products/" +
        this.$route.params.id
    );
  }*/
};
</script>

<style scoped></style>

<template>
  <div>
    <mkp-breadcrumbs :title="product.label" />
    <mkp-presentation-product
      :id="product.id"
      :label="product.label"
      :image="require('@/assets/images/' + product.media[0].url)"
      :version="product.versions[0].startDate"
      :description="product.descriptions[0].short"
      @addToCart="addToCard()"
    />
    <mkp-description-product
      :description="product.descriptions[0].full"
      :hastags="product.tags"
    />
    <mkp-detail-product
      :medias="product.media.slice(1)"
      :options="product.options"
    />
  </div>
</template>

<script>
import MkpBreadcrumbs from "../../../components/views/product/mkp-breadcrumbs";
import MkpPresentationProduct from "../../../components/views/product/mkp-presentation-product";
import MkpDescriptionProduct from "../../../components/views/product/mkp-description-product";
import MkpDetailProduct from "../../../components/views/product/mkp-detail-product";
import { getValidBaseUrl } from "../../../utils/dataValidator.js";

export default {
  name: "MkpProductDefaultTemplate",
  components: {
    MkpDetailProduct,
    MkpDescriptionProduct,
    MkpPresentationProduct,
    MkpBreadcrumbs
  },
  data() {
    return {
      product: this.$store.getters.getProduct
    };
  },
  methods: {
    addToCard() {
      let product = {
        id: this.$props.product.id,
        label: this.$props.product.label,
        quantity: 1,
        price: "0"
      };
      this.$store.dispatch("addProduct", {
        url: getValidBaseUrl() + process.env.VUE_APP_BASE_URL_API + "/carts",
        product: product
      });
      this.$notify({
        group: "default",
        type: "notification",
        title: "Ajout au panier effectué"
      });
    }
  }
};
</script>

<style scoped></style>
